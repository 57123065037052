<template>
  <div v-show="isActive" class="commonTabsTab">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tab',

  props: {
    title: {
      type: String,
      required: true
    },
    hasSettings: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isActive: true
    }
  }
}
</script>

<style lang="scss" scoped>
.commonTab {
  width: 100%;
}
</style>
