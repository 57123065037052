<template lang="pug">
nuxt-link.episode(
  :to='episodeLink'
)
  .episode__content
    .episode__cover
      .episode__cover-box
        img.episode__cover-img(
          :src="episodeCoverUrl.default"
          :srcset="episodeCoverUrl.retina + ' 2x'"
        )
        span.episode__type(
          v-if="episodeType !== 'full'"
        ) {{ $t('single_words.' + episodeType) }}
      h3.episode__name
        span(v-html="episodeTitle")

    .episode__main
      .episode__main-top
        PlayPauseButton.episode__play-pause(
          :loading="loading && content && content.id === currentEpisodeId"
          :isPlaying="isPlaying && status === 'playing'"
          @on-click="playPauseEpisode"
        )
        h3.episode__name
          span(v-html="episodeTitle")
          UiExplicitTag(
            v-if="episodeExplicit === 'yes'"
          )
      p.episode__description {{ episodeDescription }}
      .episode__created {{ formatDate(content.pub_date) }}

    .episode__bottom
      .episode__created {{ formatDate(content.pub_date) }}
      .episode__countdown
        ClockIcon
        span(v-if="(!isPlaying || status !== 'playing') && (content.id !== currentEpisodeId)") {{ duration }}
        span(v-else) {{ countdown }}
      PlayPauseButton.episode__play-pause(
        :loading="loading && content && content.id === currentEpisodeId"
        :isPlaying="isPlaying && status === 'playing'"
        @on-click="playPauseEpisode"
      )
</template>

<script>
import stripTags from 'striptags'
import { mapState, mapGetters, mapActions } from 'vuex'
import { getResizedImageUrl } from '@/lib/utils'

import ClockIcon from '@/components/icons/dashboard/Clock'
import PlayPauseButton from '@/components/ui/controls/PlayPauseButton'
import IconEpisodeItemArrow from '@/components/icons/dashboard/EpisodeItemArrow.vue'

export default {
  components: {
    ClockIcon,
    PlayPauseButton,
    IconEpisodeItemArrow
  },

  props: {
    content: {
      type: Object,
      required: true
    },
    publicLink: {
      type: Boolean,
      default: false
    },
    podcast: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      isPlaying: false
    }
  },

  computed: {
    ...mapState('i18n', ['locale']),
    ...mapState('player', ['status', 'currentEpisode', 'countdown', 'loading', 'currentEpisodeId']),
    ...mapGetters('podcasts', ['podcastByPath']),

    podcastSlug () {
      return this.$route.params.slug
    },

    currentPodcast () {
      return this.podcastByPath(this.podcastSlug)
    },

    episodeTitle () {
      if (!this.episodeNumbers) {
        return this.content.title
      }
      return this.episodeNumbers + ' - ' + this.content.title
    },

    episodeType () {
      if (!this.content.itunes_episode_type) {
        return 'full'
      }
      return this.content.itunes_episode_type
    },

    episodeDescription () {
      if (typeof this.content.description !== 'string') {
        return ''
      }
      let description = stripTags(this.content.description)
      if (description.length > 230) {
        description = description.slice(0, 230) + '...'
      }
      return description
    },

    episodeCoverUrl () {
      if (!this.podcast.cover && !this.content.episode_cover) {
        return {
          default: '/images/no-cover.png',
          retina: '/images/no-cover.png'
        }
      }

      let filename = ''

      let cover = {
        default: '/images/no-cover.png',
        retina: '/images/no-cover.png'
      }

      if (this.content.episode_cover) {
        filename = this.content.episode_cover

        if (filename === 'no-cover-1400.jpg') {
          return {
            default: filename,
            retina: filename
          }
        }

        cover = getResizedImageUrl(80, this.podcastSlug, filename)
      }

      if (this.podcast.cover && !this.content.episode_cover) {
        filename = this.podcast.cover

        if (filename === 'no-cover-1400.jpg') {
          return cover
        }

        cover = getResizedImageUrl(80, this.podcastSlug, filename)
      }

      return cover
    },

    episodeNumbers () {
      let season = this.content.itunes_season
      let episode = this.content.itunes_episode

      if (!parseInt(season) && !parseInt(episode)) {
        return ''
      }
      if (season > 0 && season < 10) { season = '0' + season.toString() }
      if (episode > 0 && episode < 10) { episode = '0' + episode.toString() }

      const seasonLetter = this.$t('single_words.season')[0]
      const episodeLetter = this.$t('single_words.episode')[0]

      if (!episode) {
        return `${seasonLetter}${season}`
      }

      if (!season) {
        return `${episodeLetter}${episode}`
      }

      return `${seasonLetter}${season} ${episodeLetter}${episode}`
    },

    episodeExplicit () {
      return this.content.itunes_explicit
    },

    episodeLink () {
      return this.$locatedLink(`/podcasts/${this.podcastSlug}/${this.content.id}/`)
    },

    duration () {
      if (!this.content.itunes_duration) {
        return '--:--'
      }

      const duration = parseInt(this.content.itunes_duration)

      let result = ''

      let seconds = duration % 60
      let hours = Math.floor(duration / 60 / 60)
      let minutes = Math.floor(duration / 60) - (hours * 60)

      if (seconds > 0 && seconds < 10) {
        seconds = `0${seconds}`
      }

      if (minutes >= 0 && minutes < 10) {
        minutes = `0${minutes}`
      }

      if (hours > 0 && hours < 10) {
        hours = `0${hours}`
      }

      if (hours) {
        result += hours + ':'
      }

      result += minutes + ':' + seconds

      return result
    }
  },

  watch: {
    currentEpisode (episodeData) {
      if (!episodeData) {
        return
      }

      const playingEpisodeId = episodeData.id
      const currentEpisodeId = this.content.id

      if (playingEpisodeId === currentEpisodeId) {
        if (this.status === 'playing') {
          this.isPlaying = true
        }
        return
      }

      this.isPlaying = false
    },

    status: {
      immediate: true,

      // eslint-disable-next-line
      handler: function (newStatus) {
        if (newStatus === 'playing') {
          const playingEpisodeId = this.currentEpisode.id
          const currentEpisodeId = this.content.id

          if (playingEpisodeId === currentEpisodeId) {
            if (this.status === 'playing') {
              this.isPlaying = true
            }
            return
          }

          this.isPlaying = false
        }
      }
    }
  },

  methods: {
    ...mapActions('player', ['setPlayerStatus', 'setCurrentPlayingEpisode', 'setLoadingStatus']),

    formatDate (date) {
      const dateStr = this.$dayjs(date).locale(this.locale).format('MMM DD YYYY')
      const result = dateStr[0].toUpperCase() + dateStr.slice(1)
      return result
    },

    playPauseEpisode (episodeIsPlaying) {
      this.isPlaying = episodeIsPlaying

      if (this.isPlaying) {
        if (this.currentEpisode && (this.currentEpisode.id === this.content.id)) {
          this.setPlayerStatus('playing')
          return
        }
        this.emitForSetCurrentPlayingPodcast()
        this.setCurrentPlayingEpisode(this.content)
        return
      }

      this.setPlayerStatus('paused')
    },

    emitForSetCurrentPlayingPodcast () {
      this.$emit('onPlayEpisode')
    }
  }
}
</script>

<style lang="scss" scoped>
.episode {
  text-decoration: none;

  &__content {
    display: flex;
    padding: $gap 0;
    color: $colorText;
    position: relative;
    border-radius: 8px;
    align-items: center;
    box-sizing: border-box;
    padding: $gap $gap * 1.5;
    border: 1px solid #F1EFFF;
    transition: background .2s ease;

    &:hover {
      background: rgba(241, 239, 255, 0.1);
    }

    @include display-less(phablet) {
      display: grid;
      padding: $gap;
    }
  }

  &__cover {
    width: 80px;
    margin-right: $gap * 1.5;

    .episode__name {
      display: none;
      @include display-less(phablet) {
        display: inline-block;
      }
    }

    &-box {
      width: 80px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      @include display-less(phablet) {
        width: 64px;
        margin-right: 12px;
      }
    }

    &-img {
      width: 80px;
      height: 80px;
      border-radius: 8px;

      @include display-less(phablet) {
        width: 64px;
        height: 64px;
      }
    }

    @include display-less(phablet) {
      width: 100%;
      display: flex;
      margin-right: 0;
      align-items: center;
      padding-bottom: 12px;
    }
  }

  &__type {
    width: 100%;
    color: #fff;
    font-size: 12px;
    margin-top: 4px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    background: #B439FF;
    border-radius: 18px;
    text-transform: uppercase;
  }

  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;

    .episode__created {
      display: none;
    }

    &-top {
      display: flex;
      margin-bottom: 8px;
      align-items: center;
      transform: translateX(-2px);

      @include display-less(phablet) {
        display: none;
        margin-bottom: 0px;
        transform: translateX(0px);
      }
    }
  }

  &__play-pause {
    @include display-less(phablet) {
      right: $gap;
      bottom: $gap;
      display: block;
      position: absolute;
    }
  }

  &__description {
    margin: 0;
    color: #fff;
    font-size: 14px;

    @include display-less(phablet) {
      padding-top: 16px;
      position: relative;
      margin-bottom: $gap * 1.5;

      &:after {
        top: 0;
        left: 0;
        width: 90px;
        height: 1px;
        content: '';
        position: absolute;
        background-color: #fff;
      }
    }
  }

  &__numbers {
    min-width: 70px;
    color: #828282;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-right: $gap;
    white-space: nowrap;
    letter-spacing: -0.605714px;
    font-family: $fontFamilyAlt;
  }

  &__icon,
  &__arrow {
    flex: 0 0 30px;
    text-align: center;
    @include display-less(desktop) {
      display: none;
    }
  }

  &__name {
    flex: 1;
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 10px;

    span {
      font-size: 14px;
      font-weight: 600;
      margin-right: $gap / 3;
    }

    @include display-less(phablet) {
      display: none;
      margin-left: 0;
      flex-basis: calc(100% - 90px);
    }

    /deep/ span.explicit {
      margin-left: 0;
      font-size: 12px;
      padding: 2px 8px;
    }
  }

  &__created,
  &__updated {
    color: #fff;
    font-size: 14px;
    margin-top: $gap / 2;

    @include display-less(phablet) {
      order: 5;
      margin: 0;
      padding: 0;
      margin-top: 0;
      flex-basis: auto;
      .episode__label {
        margin-right: 5px;
        &:after {
          content: ':';
        }
      }
    }
  }

  &__updated {
    @include display-less(phablet) {
      margin-right: auto;
    }
  }

  &__label {
    display: block;

    @include display-less(phablet) {
      display: inline;
    }
  }

  &__countdown {
    display: flex;
    align-items: center;
    margin-bottom: auto;

    span {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      margin-left: 4px;
    }

    @include display-less(phablet) {
      order: 2;
      margin-left: 8px;
      padding-left: 12px;
      position: relative;

      &:before {
        left: 0;
        top: 50%;
        width: 4px;
        height: 4px;
        content: '';
        margin-top: -2px;
        border-radius: 4px;
        position: absolute;
        background-color: #fff;
      }
    }
  }

  @include display-less(phablet) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__bottom {
    margin-bottom: auto;
    display: flex;

    .episode__play-pause {
      display: none;
      @include display-less(phablet) {
        display: block;
      }
    }
    .episode__created {
      order: 1;
      display: none;
      @include display-less(phablet) {
        display: block;
      }
    }
  }
}
</style>
