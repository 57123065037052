<template>
  <div class="podrolls">
    <Podroll
      v-for="(podroll, index) in podrolls"
      :key="index"
      :podroll="podroll"
    />
  </div>
</template>

<script>
import Podroll from './Podroll.vue'

export default {
  name: 'Podrolls',

  components: {
    Podroll
  },

  props: {
    podrolls: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.podrolls {
  display: grid;
  grid-gap: $gap * 1.5;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include display-less(tablet) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include display-less(smart) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
