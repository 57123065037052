<template>
  <div class="commonLoader">
    <LoadingIcon class="commonLoaderIcon" />
  </div>
</template>

<script>
import LoadingIcon from '../../icons/player/Loading.vue'

export default {
  name: 'Loader',

  components: {
    LoadingIcon
  }
}
</script>

<styles lang="scss" scoped>
.commonLoader {
  width: 100%;
  display: flex;
  height: 180px;
  align-items: center;
  justify-content: center;

  &Icon {
    width: 40px;
    height: 40px;
  }
}
</styles>
