<template lang="pug">
article.podcast
  // -- Podcast Info Card
  section.podcast__content
    .podcast__content-top
      h1.podcast__title
        span(
          v-html="podcastData.title"
        )
        UiExplicitTag.podcast__explicit(
          v-if="podcastData.itunes_explicit === 'yes'"
        )
      h2.podcast__sub-title
        span(
          :class="{'podcast__sub-title-owner': !!location}"
          v-html="subTitle"
        )
        LocationLink(
          v-if="location"
          :name="podcastData.location_name"
          :coordinates="podcastData.location_coordinates.coordinates"
        )

      .podcast__text
        span.podcast__text-short(
          v-if="!fullDescriptionIsOpened"
          v-html="shortPodcastDescription"
          ref="shortPodcastDescription"
        )
        span.podcast__text-full(
          v-else
          v-html="podcastDescription"
        )

        a.podcast__text-see-more(
          href="#"
          v-if="podcastDescription && (shortDescriptionEllipsis || podcastDescription.length > podcastDescriptionMaxLength)"
          @click.prevent="fullDescriptionIsOpened = !fullDescriptionIsOpened"
        )
          template(
            v-if="!fullDescriptionIsOpened"
          ) {{ ' &nbsp;...&nbsp; ' + $t('links.read_more') }}
          template(
            v-else
          ) {{ $t('single_words.hide') }}

    .podcast__content-categories
      .podcast__category-item(
        v-for="category in podcastCategories"
      )
        NuxtLink.podcast-category(
          :to="$locatedLink('/community/categories/' + category.path + '/')"
        ) {{ getCategoryLocalizedName(category) }}

  // -- Cover Side
  aside.podcast__side
    .podcast__cover
      img.podcast__image(
        alt=""
        v-if="!podcastData.cover"
        src="/images/no-cover.png"
        srcset="/images/no-cover.png 2x"
      )
      img.podcast__image(
        alt=""
        v-else
        :src="podcastCoverUrl"
        :srcset="podcastCoverUrlRetina + ' 2x'"
      )
      img.podcast__image-blured(
        alt=""
        v-if="!podcastData.cover"
        src="/images/no-cover.png"
        srcset="/images/no-cover.png 2x"
      )
      img.podcast__image-blured(
        alt=""
        v-else
        :src="podcastCoverUrl"
        :srcset="podcastCoverUrlRetina + ' 2x'"
      )
</template>

<script>
import { mapState } from 'vuex'
import stripTags from 'striptags'
import { decode } from 'html-entities'

import { config } from '@/config'
import { getMobileOperatingSystem } from '@/lib/utils'

import LocationLink from '@/components/elements/location-link/LocationLink.vue'
import CopyRssFeedButton from '@/components/shared/elements/CopyRssFeedButton.vue'
import LazyLoadingImage from '@/components/ui/elements/LazyLoadingImage'

export default {
  components: {
    LocationLink,
    LazyLoadingImage,
    CopyRssFeedButton
  },

  props: {
    readOnly: {
      type: Boolean,
      default: true
    },

    podcastData: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      mobileOperatingSystem: null,
      fullDescriptionIsOpened: false,
      shortDescriptionEllipsis: false
    }
  },
  computed: {
    ...mapState('player', ['currentPodcast', 'currentEpisode', 'currentEpisodeId']),

    playerIsOpened () {
      return this.currentPodcast && this.currentEpisode && this.currentEpisodeId
    },

    shareTitle () {
      return this.podcastData.title.replace(/<[^>]*>?/gm, '')
    },

    subTitle () {
      return this.$t('single_words.by') + ' <strong>' + this.podcastData.author_name + '</strong>'
    },

    shortPodcastDescription () {
      let description = stripTags(this.podcastData.description, ['p', 'a', 'ul', 'ol', 'li', 'i', 'b', 'strong', 'br', 'em']) || ''
      description = description.replace(/<a /g, '<a rel="noopener nofollow" ')

      if (description && description.length < this.podcastDescriptionMaxLength + 1) {
        return decode(description)
      }

      const shortDescription = this.closeHtmlTags(description.slice(0, this.podcastDescriptionMaxLength + 1) + '&nbsp;...&nbsp;')
      return decode(shortDescription)
    },

    podcastDescription () {
      const description = stripTags(this.podcastData.description, ['p', 'a', 'ul', 'ol', 'li', 'i', 'b', 'strong', 'br', 'em']) || ''
      return decode(description.replace(/<a /g, '<a rel="noopener nofollow" target="_blank"'))
    },

    podcastDescriptionMaxLength () {
      return !this.mobileOperatingSystem ? 430 : 160
    },

    shareDescription () {
      if (!this.podcastData.description) {
        return ''
      }
      return this.podcastData.description.replace(/<[^>]*>?/gm, '')
    },

    shareLink () {
      return config.baseUrl + this.$locatedLink('/podcasts/' + this.podcastSlug + '/')
    },

    podcastSlug () {
      return this.$route.params.slug.toLowerCase() || ''
    },

    podcastCategories () {
      if (!this.podcastData || !this.podcastData.itunes_categories) {
        return []
      }

      if (!Array.isArray(this.podcastData.itunes_categories)) {
        return [this.podcastData.itunes_categories]
      }

      this.podcastData.itunes_categories.map((category) => {
        category.path = category.localized_label
          .replace(/itunes.categories./g, '')
          .replace(/.categories./g, '')
          .replace(/_/g, '-')
          .replace(/:/g, '/')
        return category
      })

      return this.podcastData.itunes_categories
    },

    podcastCoverUrl () {
      const image = this.podcastData.cover
      return `${config.imageResizerUrl}/${this.podcastSlug}/400/${image}`
    },

    podcastCoverUrlRetina () {
      const image = this.podcastData.cover
      return `${config.imageResizerUrl}/${this.podcastSlug}/800/${image}`
    },

    location () {
      if (!this.podcastData) {
        return false
      }

      if (!this.podcastData.location_name || !this.podcastData.location_coordinates || !this.podcastData.location_coordinates.coordinates) {
        return false
      }

      return true
    }
  },
  mounted () {
    this.mobileOperatingSystem = getMobileOperatingSystem()
    this.checkShortDescriptionEllipsis()
    window.addEventListener('resize', this.checkShortDescriptionEllipsis)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkShortDescriptionEllipsis)
  },
  methods: {
    getCategoryLocalizedName (category) {
      if (category.localized_label.split(':').length > 1) {
        const nameOfSubCategory = category.localized_label.split(':')[1].split('.')[2]
        return this.$t('itunes' + category.localized_label.split(':')[0] + '.' + nameOfSubCategory)
      }
      return this.$t('itunes' + category.localized_label + '._name')
    },

    closeHtmlTags (text) {
      // Closes open html tags by the slice
      const stack = []
      const regex = /<([^<>]+)>/g

      text = this.fixSlicedTag(text)

      const fullTags = text.match(regex)
      if (fullTags === null) {
        return text
      }
      const tags = fullTags.map(match => match.slice(1, -1).split(' ')[0])

      for (const tag of tags) {
        if (tag[0] === '/') {
          // Close tag
          stack.pop()
        } else if (tag[tag.length - 1] === '/') {
          // Self-closing tag
          continue
        } else {
          // Open tag
          stack.push(tag)
        }
      }

      while (stack.length > 0) {
        const tag = stack.pop()
        text += `</${tag}>`
      }

      return text
    },

    fixSlicedTag (text) {
      // Removes the tag in case it has been sliced.
      for (let i = text.length - 1; i >= 0; i--) {
        if (text[i] === '>') {
          break
        }

        if (text[i] === '<') {
          return text.slice(0, i)
        }
      }
      return text
    },

    checkShortDescriptionEllipsis () {
      const paragraphElement = this.$refs.shortPodcastDescription
      this.shortDescriptionEllipsis = paragraphElement?.scrollHeight > paragraphElement?.clientHeight
    }
  }
}
</script>

<style lang="scss">
.podcast-description-modal {
  padding: $gap;
  border-radius: 5px;
  background-color: #fff;

  ul, ol {
    padding-left: $gap;

    li {
      &:not(:last-child) {
        width: 100%;
        float: left;
        margin-bottom: $gap / 2;
      }
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }

  ol {
    li {
      list-style: decimal;
    }
  }

  &-wrapper {
    &_player {
      padding-bottom: 96px;
      .modal-content {
        max-height: calc(100vh - 180px)!important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
$sideWidth: 268px;

.podcast {
  @include display(desktop) {
    display: grid;
    grid-column-gap: 24px;
    align-items: flex-start;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: $sideWidth 1fr;
    grid-template-areas:  'side header'
                          'side content'
                          'side footer';
  }

  @include display-less(desktop) {
    display: grid;
    grid-column-gap: $gap;
    grid-template-columns: 200px 1fr;
    grid-template-areas:  'side header'
                          'side content'
                          'side footer';
  }

  @include display-less(phablet) {
    display: flex;
    flex-direction: column;
    /deep/ .explicit {
      margin-left: 0!important;
    }
  }

  &__explicit {
    margin-left: 0;
    display: inline-block;
    transform: translateY(-6px);
  }

  &__side {
    margin: 0;
    grid-area: side;

    @include display(desktop) {
      margin: 0;
      float: none;
      max-width: $sideWidth;
    }

    @include display-less(desktop) {
      margin: 0;
    }

    @include display-less(phablet) {
      order: 1;
      width: 100%;
    }
  }

  &__content {
    min-height: 268px;
    display: flex;
    grid-area: content;
    flex-direction: column;
    justify-content: space-between;

    &-categories {
      display: flex;
      margin-top: $gap;
    }

    @include display-less(desktop) {
      height: auto;
      margin-top: 0;
      max-width: 100%;
      min-height: auto;
      border-radius: 25px;
      display: inline-block;
      font-size: $fontSizeSmall;
    }

    @include display-less(phablet) {
      order: 2;
      padding: 0;
      display: block;
      max-width: 100%;
      min-height: auto;
      border-radius: 0;
      background: transparent;
      font-size: $fontSizeBase;
    }
  }

  &__title {
    margin: 0;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;

    span {
      margin-right: $gap;
    }

    @media screen and (max-width: 948px) {
      border-radius: 25px;

      span {
        width: 100%;
        margin-right: 0;
        display: inline-block;
      }

      /deep/ .explicit {
        width: auto;
        margin-left: 0!important;
      }
    }
  }

  &__sub-title {
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: $gap;
    display: flex;
    align-items: center;

    /deep/ strong {
      color: #fff;
    }

    &-owner {
      margin-right: 13px;
      padding-right: 13px;
      border-right: 1px solid #797088;
    }
  }

  &__action {
    flex: 0 0 auto;
    margin: $gap 0 0;

    @include display(smart) {
      margin-right: $gap;
    }
  }

  &__cover {
    width: 268px;
    height: 268px;
    position: relative;
    border-radius: 18px;

    @include display-less(desktop) {
      width: 200px;
      height: 200px;
    }

    @include display-less(phablet) {
      width: 50%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $gap * 1.5;
    }
  }

  &__image {
    z-index: 5;
    width: 268px;
    height: 268px;
    object-fit: cover;
    position: relative;
    border-radius: 18px;
    vertical-align: bottom;

    &-blured {
      top: 5px;
      left: 5px;
      z-index: 3;
      width: 258px;
      height: 258px;
      object-fit: cover;
      position: absolute;
      filter: blur(16px);
      border-radius: 18px;
      vertical-align: bottom;

      @include display-less(desktop) {
        width: 200px;
        height: 200px;
      }

      @include display-less(phablet) {
        width: 100%;
        height: auto;
      }
    }

    @include display-less(desktop) {
      width: 200px;
      height: 200px;
    }

    @include display-less(phablet) {
      width: 100%;
      height: auto;
    }
  }

  &__text {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    overflow-wrap: anywhere; // To prevent overflow
    &-short {
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 5px;
    }
    &-full {
      margin-bottom: $gap;
      display: block;
      padding-left: 5px;
    }
    &-see-more {
      text-decoration: none;
      font-weight: bold;
      display: inline-block;
    }

    /deep/ strong {
      color: #fff;
    }

    /deep/ strong {
      color: #fff;
    }

    /deep/ strong {
      color: #fff;
    }

    /deep/ ul {
      padding-left: $gap;
      li {
        list-style: disc;
      }
    }

    /deep/ ol {
      padding-left: $gap;
      li {
        list-style: decimal;
      }
    }

    /deep/ p {
      margin-top: 0;
      margin-bottom: 0;
    }

    /deep/ a {
      color: $colorLightPurple;
      &:hover {
        color: $colorLight;
        background-color: $colorLightPurple;
        text-decoration: none;
      }
    }
  }

  &__category-item {
    margin-right: $gap / 2;
  }
}

.podcast-category {
  color: #fff;
  font-size: 14px;
  padding: 4px 8px;
  font-weight: 500;
  border-radius: 4px;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.08);
}
</style>
