<template lang="pug">
nuxt-link.episode(
  :to='episodeLink'
)
  .episode__content
    .episode__cover
      .episode__cover-box
        img.episode__cover-img(
          :src="episodeCoverUrl.default"
          :srcset="episodeCoverUrl.retina + ' 2x'"
        )
      h3.episode__name
        span(v-html="content.title")
      .episode__cover-tags
        span.episode__cover-tags-type(
          v-if="episodeType !== 'full'"
        ) {{ $t('single_words.' + episodeType) }}
        UiExplicitTag.episode__cover-tags-explicit(
          v-if="episodeExplicit === 'yes'"
        )

    .episode__main
      .episode__main-top
        PlayPauseButton.episode__play-pause(
          :loading="loading && content && content.id === currentEpisodeId"
          :isPlaying="isPlaying && status === 'playing'"
          @on-click="playPauseEpisode"
        )
        .episode__title
          h3.episode__title-name
            span(v-html="content.title")
          .episode__title-metadata
            .episode__title-metadata-episode(v-if="episodeNumbers")
              span {{ episodeNumbers }}
            .episode__title-metadata-created
              CalendarIcon.episode__title-metadata-created-icon
              client-only {{ creationDate }}
            .episode__title-metadata-countdown
              ClockIcon.episode__title-metadata-countdown-icon
              span(v-if="!isPlaying || status === 'stopped'") {{ duration }}
              span(v-else) {{ countdown }}
        .episode__tags
          span.episode__tags-type(
            v-if="episodeType !== 'full'"
          ) {{ $t('single_words.' + episodeType) }}
          UiExplicitTag.episode__tags-explicit(
            v-if="episodeExplicit === 'yes'"
          )
      p.episode__description {{ episodeDescription }}
</template>

<script>
import stripTags from 'striptags'
import { mapState, mapGetters, mapActions } from 'vuex'

import toast from '@/lib/toast'
import { config } from '@/config'
import { getResizedImageUrl, formatTextDate } from '@/lib/utils'

import ClockIcon from '@/components/icons/dashboard/Clock'
import CalendarIcon from '@/components/icons/dashboard/Calendar'
import PlayPauseButton from '@/components/ui/controls/PlayPauseButton'
import IconEpisodeItemArrow from '@/components/icons/dashboard/EpisodeItemArrow.vue'

export default {
  components: {
    ClockIcon,
    CalendarIcon,
    PlayPauseButton,
    IconEpisodeItemArrow
  },

  props: {
    content: {
      type: Object,
      required: true
    },

    publicLink: {
      type: Boolean,
      default: false
    },

    podcast: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      isPlaying: false,
      durationFromAudioFile: null
    }
  },

  computed: {
    ...mapState('i18n', ['locale']),
    ...mapState('player', ['status', 'currentEpisode', 'countdown', 'loading', 'currentEpisodeId']),

    ...mapGetters('podcasts', ['podcastByPath']),

    podcastSlug () {
      if (!this.$route || !this.$route.params || !this.$route.params.slug) {
        return
      }
      return this.$route.params.slug.toLowerCase()
    },

    currentPodcast () {
      return this.podcastByPath(this.podcastSlug)
    },

    episodeType () {
      if (!this.content.itunes_episode_type) {
        return 'full'
      }
      return this.content.itunes_episode_type
    },

    episodeDescription () {
      if (typeof this.content.description !== 'string') {
        return ''
      }

      let description = this.content.description.replace(/<\/(p|ul|li)>/g, ' ') // replace </p|ul|li> with an space
      description = stripTags(description)
      description = description.replace(/&amp;/g, '&')
      description = description.replace(/\s+/g, ' ') // removes possible multi-spaces

      return description.trim()
    },

    episodeCoverUrl () {
      if (!this.podcast.cover && !this.content.episode_cover) {
        return {
          default: '/images/no-cover.png',
          retina: '/images/no-cover.png'
        }
      }
      let filename = ''
      let cover = {
        default: '/images/no-cover.png',
        retina: '/images/no-cover.png'
      }
      if (this.content.episode_cover) {
        filename = this.content.episode_cover
        if (filename === 'no-cover-1400.jpg') {
          return {
            default: filename,
            retina: filename
          }
        }
        cover = getResizedImageUrl(80, this.podcastSlug, filename)
      }
      if (this.podcast.cover && !this.content.episode_cover) {
        filename = this.podcast.cover
        if (filename === 'no-cover-1400.jpg') {
          return cover
        }
        cover = getResizedImageUrl(80, this.podcastSlug, filename)
      }
      return cover
    },

    episodeNumbers () {
      let season = this.content.itunes_season
      let episode = this.content.itunes_episode
      if (!parseInt(season) && !parseInt(episode)) {
        return ''
      }
      if (season > 0 && season < 10) { season = '0' + season.toString() }
      if (episode > 0 && episode < 10) { episode = '0' + episode.toString() }
      const seasonLetter = this.$t('single_words.season')[0]
      const episodeLetter = this.$t('single_words.episode')[0]
      if (!episode) {
        return `${seasonLetter}${season}`
      }
      if (!season) {
        return `${episodeLetter}${episode}`
      }
      return `${seasonLetter}${season} ${episodeLetter}${episode}`
    },

    episodeExplicit () {
      return this.content.itunes_explicit
    },

    episodeLink () {
      return this.$locatedLink(`/podcasts/${this.podcastSlug}/${this.content.id}/`)
    },

    duration () {
      let duration

      if (!this.content.itunes_duration) {
        duration = this.durationFromAudioFile
      } else {
        duration = parseInt(this.content.itunes_duration)
      }

      if (!duration) {
        return '--:--'
      }

      let result = ''
      let seconds = duration % 60
      let hours = Math.floor(duration / 60 / 60)
      let minutes = Math.floor(duration / 60) - (hours * 60)
      if (seconds >= 0 && seconds < 10) {
        seconds = `0${seconds}`
      }
      if (minutes >= 0 && minutes < 10) {
        minutes = `0${minutes}`
      }
      if (hours >= 0 && hours < 10) {
        hours = `0${hours}`
      }
      if (hours && hours !== '00') {
        result += hours + ':'
      }
      result += minutes + ':' + seconds
      return result
    },

    creationDate () {
      return formatTextDate(this.content.pub_date, this.locale)
    }
  },

  watch: {
    currentEpisode (episodeData) {
      const playingEpisodeId = episodeData.id
      const currentEpisodeId = this.content.id
      if (playingEpisodeId === currentEpisodeId) {
        if (this.status === 'playing') {
          this.isPlaying = true
        }
        return
      }
      this.isPlaying = false
    },

    status: {
      immediate: true,
      // eslint-disable-next-line
      handler: function (newStatus) {
        if (newStatus === 'playing') {
          const playingEpisodeId = this.currentEpisode.id
          const currentEpisodeId = this.content.id
          if (playingEpisodeId === currentEpisodeId) {
            if (this.status === 'playing') {
              this.isPlaying = true
            }
            return
          }
          this.isPlaying = false
        }
      }
    }
  },

  mounted () {
    if (!this.content.itunes_duration) {
      // Check audio duration with audio HTML element
      const $audio = document.createElement('audio')
      $audio.setAttribute('autobuffer', true)
      $audio.setAttribute('preload', 'metadata')

      const audioSrc = `${config.mediaUrl}/${this.podcastSlug}/${this.content.episode_asset}`
      $audio.setAttribute('src', audioSrc)

      const loadAudioDurationEventListener = $audio.addEventListener('loadedmetadata', (event) => {
        this.durationFromAudioFile = Math.floor(event.srcElement.duration)
        removeEventListener(loadAudioDurationEventListener)
      })
    }
  },

  methods: {
    ...mapActions('player', ['setPlayerStatus', 'setCurrentPlayingEpisode', 'setLoadingStatus']),

    async playPauseEpisode (action) {
      // Don't start playing m4a files on Safari
      // eslint-disable-next-line
      const ua = navigator.userAgent.toLowerCase() 
      if (ua.includes('safari')) {
        if (!ua.includes('chrome')) {
          // eslint-disable-next-line
          if (this.content && this.content.episode_asset && this.content.episode_asset.endsWith('.m4a')) {
            toast(this.$t('toast_notifications.errors.audio_file__not_available'), 'danger')
            return
          }
        }
      }

      this.isPlaying = action

      if (action) {
        if (this.currentEpisode && (this.currentEpisode.id === this.content.id)) {
          this.setPlayerStatus('playing')
          return
        }

        const playlist = await this.$store.dispatch('podcasts/getPlaylist', this.podcast.slug) || []
        await this.$store.dispatch('player/setPlayList', playlist)

        this.setLoadingStatus(true)
        this.setCurrentPlayingEpisode(this.content)
        this.setPlayerStatus('playing')
        this.$emit('onPlayEpisode')
        return
      }
      this.setPlayerStatus('stopped')
    }
  }
}
</script>

<style lang="scss" scoped>
.episode {
  text-decoration: none;
  &__content {
    display: flex;
    padding: $gap 0;
    color: $colorText;
    position: relative;
    border-radius: 8px;
    align-items: center;
    box-sizing: border-box;
    padding: $gap $gap * 1.5;
    border: 1px solid #F1EFFF;
    transition: background .2s ease;
    &:hover {
      background: rgba(241, 239, 255, 0.1);
    }
    @include display-less(phablet) {
      display: flex;
      padding: $gap;
      flex-direction: column;
    }
  }
  &__cover {
    width: 80px;
    margin-right: $gap * 1.5;
    .episode__name {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      display: none;
      @include display-less(phablet) {
        overflow: hidden;
        margin-right: $gap;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    &-tags {
      display: none;
      @include display-less(phablet) {
        justify-self: end;
        margin-left: auto;
        align-self: flex-start;
        margin-top: 0;
        display: inline-block;
      }
      &-type {
        @include display-less(phablet) {
          color: #6C26D6;
          font-size: 14px;
          margin-top: 4px;
          font-weight: 500;
          background: #fff;
          border-radius: 4px;
          padding: 4px 8px;
        }
      }
    }
    &-box {
      width: 90px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      @include display-less(phablet) {
        width: 64px;
        margin-right: 12px;
      }
    }
    &-img {
      width: 90px;
      height: 90px;
      border-radius: 8px;
      @include display-less(phablet) {
        width: 56px;
        height: 56px;
      }
    }
    @include display-less(phablet) {
      width: 100%;
      display: flex;
      margin-right: 0;
      align-items: center;
      padding-bottom: 12px;
    }
  }
  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include display-less(phablet) {
      flex-direction: column-reverse;
    }
    &-top {
      display: flex;
      margin-bottom: 8px;
      align-items: center;
      transform: translateX(-2px);
      @include display-less(phablet) {
        margin-bottom: 0px;
        transform: translateX(0px);
        flex-direction: row-reverse;
      }
    }
  }
  &__play-pause {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  &__description {
    margin: 0;
    color: #fff;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
    @include display-less(phablet) {
      padding-top: 12px;
      position: relative;
      margin-bottom: 12px;
    }
  }
  &__tags {
    @include display-less(phablet) {
      display: none;
    }
    &-type {
      color: #6C26D6;
      font-size: 14px;
      margin-top: 4px;
      font-weight: 500;
      background: #fff;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }
  &__title {
    color: #fff;
    margin-left: 10px;
    width: 100%;
    @include display-less(phablet) {
      margin-left: 0;
    }
    &-name {
      flex: 1;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-right: $gap;
      overflow: hidden;
      span {
        font-size: 14px;
        font-weight: 600;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      @include display-less(phablet) {
        display: none;
        margin-left: 0;
        flex-basis: calc(100% - 90px);
      }
      /deep/ span.explicit {
        margin-left: 0;
        font-size: 12px;
        padding: 2px 8px;
      }
    }
    &-metadata {
      font-size: 14px;
      display: flex;
      &-episode {
        margin-right: 8px;
        padding-right: 8px;
        border-right: 1px solid #797088;
      }
      &-created {
        display: flex;
        margin-right: 8px;
        padding-right: 8px;
        border-right: 1px solid #797088;
        &-icon {
          margin-right: 5px;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
      &-countdown {
        display: flex;
        &-icon {
          width: 20px;
          margin-right: 5px;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }
  }
  @include display-less(phablet) {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
