<template lang="pug">
.s-and-d
  .s-and-d__row
    CopyRssFeedButton.s-and-d__copy-rss-feed.s-and-d__instance(
      white
    )

    Donation.s-and-d__instance(
      :podcastData="podcastData"
    )

    CustomWebsite.s-and-d__instance(
      :podcastData="podcastData"
    )

    Distribution.s-and-d__distribution.s-and-d__instance(
      :podcastData="podcastData"
      v-if="podcastData && podcastData.directories && podcastData.directories.length"
    )

    ShareNetworkElements.share__component.s-and-d__instance
</template>

<script>
import Donation from '@/components/shared/elements/donation/index'
import Distribution from '@/components/shared/elements/distribution/index'
import CustomWebsite from '@/components/shared/elements/custom-website/index'
import CopyRssFeedButton from '@/components/shared/elements/CopyRssFeedButton'
import ShareNetworkElements from '@/components/shared/elements/share-network-elements/index'

export default {
  components: {
    Donation,
    Distribution,
    CustomWebsite,
    CopyRssFeedButton,
    ShareNetworkElements
  },

  props: {
    podcastData: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="scss" scoped>
.s-and-d {
  width: 100%;
  display: flex;
  margin-bottom: $gap * 2;
  font-size: 16px;

  &__row {
    display: flex;
    gap: $gap;
    flex-wrap: wrap;
    align-items: center;

    @include display-less(phablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $gap 13px;
    }
  }

  &__instance {
    @include display-less(smart) {
      height: 40px;
      /deep/ a {
        height: 40px;
      }
    }
  }

  @include display-less(tablet) {
    flex-direction: column;

    .s-and-d__row {
      &:not(:last-child) {
        margin-bottom: $gap * 1.5;
      }
    }
  }
}
</style>
