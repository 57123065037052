<template>
  <div :class="{ commonBadge: true, [color]: true, [size]: true }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small',
      validator: (value) => {
        return ['small'].includes(value)
      }
    },
    color: {
      type: String,
      default: 'purple',
      validator: (value) => {
        return ['purple'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.commonBadge {
  font-size: 14px;
  font-weight: bold;
  line-height: 150%;
  border-radius: 4px;
  display: inline-block;

  &.small {
    padding: 0 8px;
  }

  &.purple {
    color: #fff;
    background-color: #2F2144;
  }
}
</style>
