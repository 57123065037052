<template>
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.333 1L1.666 8.997 8.333 17"
      stroke="#F1EFFF"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
}
</script>

<style>
</style>
