<template lang="pug">
  .podcasts
    .podcasts__view
      img.podcasts__icon(
        src="/images/planet_2.svg"
        alt=""
      )

    .podcasts__text(
      v-html="text || $t('empty_episodes')"
    )

    .podcasts__action(
      v-if="hasButton"
    )
      RssUiButton(
        flat
        rainbow
        :to="link"
        size="big"
        icon="plus"
        :width="264"
        :text-size="16"
        icon-type="far"
        text-color="#fff"
        v-if="emailVerified"
      ) {{ buttonText }}
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    text: {
      type: String,
      default: undefined
    },
    buttonText: {
      type: String,
      default: 'Add'
    },
    link: {
      type: String,
      default: '/dashboard/new-podcast/'
    },
    hasButton: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapState('auth', ['emailVerified']),

    podcastSlug () {
      return this.$route.params.slug || ''
    }
  },

  methods: {
    submit () {
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
.podcasts {
  padding: $gap*2 $gap*1.5;
  border: 1px dashed rgba(#6c26d6, .24);
  border-radius: 4px;
  text-align: center;

  &__view {

  }

  &__icon {
    height: 107px;
    vertical-align: bottom;
  }

  &__text {
    margin: $gap 0 0;
    color: $colorTextSecondary;
  }

  &__action {
    margin-top: 40px;
  }
}
</style>
