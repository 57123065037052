<template>
  <div class="episodes">
    <EmptyPodcasts
      v-if="!podcast || !podcast.episodes || podcast.episodes.length === 0"
      class="podcasts"
      :text="emptyText"
      :has-button="false"
      :button-text="$t('new_episode')"
      @add="$emit('add')"
    />

    <template v-else>
      <EpisodesList
        :is-with-title="!podcast.podroll || !podcast.podroll.length"
        :podcast="podcast"
        :episodes="episodes"
        :read-only="readOnly"
        :is-loading="isLoading"
        :title="$t('podcast_episodes')"
      >
        <div slot="settings">
          <slot name="settings" />
        </div>
      </EpisodesList>
    </template>
  </div>
</template>

<script>
import EpisodesList from '@/components/modules/episodes/list'
import EmptyPodcasts from '@/components/shared/empty/Podcasts'

export default {
  components: {
    EpisodesList,
    EmptyPodcasts
  },

  props: {
    podcast: {
      type: Object,
      default () {
        return {}
      }
    },
    emptyText: {
      type: String,
      default: undefined
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    episodes: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.episodes {
  &__list {
    padding: $gap $gap*2;
    border-radius: 4px;
    background: $colorLight;
    box-shadow: 0 0 1px 0 rgba(14,8,60,0.08), 0 4px 13px -1px rgba(14,8,60,0.12);
  }

  &__item {
    border-top: 1px solid #d0d0d0;

    &:first-child {
      border: none;
    }
  }
}
</style>
