<template>
  <nav v-if="totalPagesCount > 1" aria-label="Pagination" class="commonPagination">
    <Paragraph is-without-margin>
      {{ text }}
    </Paragraph>
    <button aria-label="Previous page" :class="['arrow', { arrowDisabled: page === 1 }]" @click="handleClickPrevious">
      <Arrow class="arrowSvg" />
    </button>
    <button aria-label="Next page" :class="['arrow', 'arrowRight', { arrowDisabled: page === totalPagesCount }]" @click="handleClickNext">
      <Arrow class="arrowSvg" />
    </button>
  </nav>
</template>

<script>
import Arrow from '../../pages/switch-to-rss/reviews/Arrow'
import Paragraph from '../Paragraph/Paragraph.vue'

export default {
  components: {
    Arrow,
    Paragraph
  },

  props: {
    page: {
      type: Number,
      default: 1
    },
    totalPagesCount: {
      type: Number,
      default: 0
    }
  },

  computed: {
    text () {
      return `${this.page} ${this.$t('single_words.of').toLowerCase()} ${this.totalPagesCount}`
    }
  },

  methods: {
    handleClickPrevious (event) {
      event.preventDefault()
      if (this.page === 1) {
        return
      }
      this.$emit('onClickPrevious')
    },

    handleClickNext (event) {
      event.preventDefault()
      if (this.page === this.totalPagesCount) {
        return
      }
      this.$emit('onClickNext')
    }
  }
}

</script>

<style lang="scss" scoped>
.commonPagination {
  gap: $gap;
  display: flex;
  align-items: center;
}

.arrow {
  width: 40px;
  height: 40px;
  font-size: 0;
  outline: none;
  display: flex;
  cursor: pointer;
  box-shadow: none;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid rgba(241, 239, 255, 0.30);

  &Right {
    .arrowSvg {
      transform: rotate(180deg);
    }
  }

  &Disabled {
    opacity: 0.4;
    cursor: default;
  }
}
</style>
