<template>
  <a
    class="podroll"
    target="_blank"
    :href="url"
    :class="{'podroll_light': theme === 'light', 'podroll_purple': theme === 'purple' }"
  >
    <div class="podrollCover">
      <img
        :src="podroll.artwork"
      >
    </div>
    <div class="podrollInfo">
      <h4 class="podrollTitle">
        {{ title }}
      </h4>
      <span class="podrollAuthor">
        {{ podroll.author }}
      </span>
      <div class="podrollCategories">
        <Badge
          v-for="(category, index) in categories"
          :key="index"
          class="podrollBadge"
          :color="'purple'"
          :size="'small'"
        >
          {{ category }}
        </Badge>
      </div>
    </div>
  </a>
</template>

<script>
import { config } from '@/config'
import Badge from '@/components/common/Badge/Badge'

export default {
  name: 'Podroll',

  components: {
    Badge
  },

  props: {
    podroll: {
      type: Object,
      default: () => {}
    },

    theme: {
      type: String,
      default: 'dark',
      validator: (value) => {
        return ['dark', 'light', 'purple'].includes(value)
      }
    }
  },

  computed: {
    title () {
      let title = this.podroll.title
      if (title.length > 40) {
        title = title.slice(0, 40) + '...'
      }
      return title
    },

    categories () {
      const categories = this.podroll.categories
      return categories ? Object.values(categories).slice(0, 2) : []
    },

    url () {
      if (!this.podroll) {
        return ''
      }

      if (this.podroll.url && this.podroll.url.includes(config.mediaUrl)) {
        const podcastSlug = this.podroll.url.split('/')[3]
        return `${config.baseUrl}/podcasts/${podcastSlug}`
      } else {
        return `https://podcastindex.org/podcast/${this.podroll.id}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.podroll {
  width: 100%;
  box-shadow: none;
  text-decoration: none;
  transition: all .2s ease;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    opacity: 0.9;
  }

  &Cover {
    width: 100%;
    display: flex;
    padding-top: 100%;
    position: relative;
    margin-bottom: $gap * 0.5;

    img {
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
      position: absolute;
      object-position: center center;
      background-color: rgba(#fff, .2);
    }
  }

  &Info {
    display: flex;
    flex-direction: column;
    gap: $gap * 0.25;
    overflow: hidden;
  }

  &Title {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 27px;
    line-height: 150%;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Author {
    color: #DFDFDF;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: $gap * 0.5;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;

    strong {
      font-size: 14px;
      font-weight: 600;
      color: #DFDFDF;
    }
  }

  &Categories {
    display: flex;
    max-width: 100%;
    gap: $gap * 0.5;
  }

  &Badge {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_light {
    .podroll__title {
      color: #2D2D2D;
    }

    .podroll__author {
      color: #979797;
      strong {
        color: #979797;
      }
    }
  }

  &_purple {
    .podroll__title {
      color: #2D2D2D;
    }

    .podroll__author {
      color: #797088;
      strong {
        color: #797088;
      }
    }
  }
}
</style>
